import * as React from "react";
import { Item } from "semantic-ui-react";
import { FeedCybozuInsideOut, FeedKintoneEngineeringBlog } from "../graphql-types";

interface FeedElement {
  node: FeedCybozuInsideOut|FeedKintoneEngineeringBlog;
}

const HatenaFeedList: React.FC<Array<FeedElement>> = ({ feeds }) => {
  return (
    <Item.Group link divided relaxed="very">
      {feeds.map((element: FeedElement) => {
      const { id, title, link, isoDate, content, enclosure } = element.node;
      const date = new Date(isoDate);
      return (
        <Item key={id} href={link} target="_blank" rel="noreferrer noopener">
          <Item.Image className="mobile hidden" size="small" src={enclosure.url}></Item.Image>
          <Item.Content>
            <Item.Header>{title}</Item.Header>
            <Item.Description>{content.replace(/(<([^>]+)>)/ig, "").slice(0, 100)}...</Item.Description>
            <Item.Extra>{date.toLocaleDateString()} {date.toLocaleTimeString()}</Item.Extra>
          </Item.Content>
        </Item>
      );
    })}
    </Item.Group>
  );
};

export default HatenaFeedList;
