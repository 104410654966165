import * as React from "react";
import { Embed, Card, SemanticWIDTHS } from "semantic-ui-react";
import { VideosYaml, VideosYamlEdge } from "../graphql-types";

interface VideosProps {
  videos: Array<VideosYamlEdge>;
  row: SemanticWIDTHS;
}

const Videos: React.FC<VideosProps> = ({ videos, row }) => {
  return (
    <Card.Group itemsPerRow={row} stackable>
      {videos.map(({node: video}: {node: VideosYaml}) => {
        if (video.service === "youtube") {
          const thumbnail = `https://i.ytimg.com/vi/${video.yamlId}/sddefault.jpg`;

          const url = `https://www.youtube.com/watch?v=${video.yamlId}`;
          return (
            <Card key={video.id} fluid>
              <Card.Content>
                <Card.Description><a href={url}>{video.title}</a></Card.Description>
                <Card.Meta>{video.createdDate}</Card.Meta>
              </Card.Content>
              <Embed key={video.id} source="youtube" id={video.yamlId} placeholder={thumbnail} />
            </Card>
          )
        } else {
          // ひとまずYoutube以外の動画は存在しないので、作らない
          return null;
        }
      })}
    </Card.Group>
  )
}

export default Videos;
