import * as React from "react";
import { Card } from "semantic-ui-react";
import { ConnectYaml } from "../graphql-types";
import ConnectCard from "./ConnectCard";

interface RecentConnectsProps {
  connects: ConnectYaml[];
}

export const RecentConnects: React.FC<RecentConnectsProps> = ({ connects }) => {
  return (
    <Card.Group doubling centered>
      {
        connects.map(connects => 
          <ConnectCard key={connects.id} connect={connects}/>)
      }
    </Card.Group>
  );
};

export default RecentConnects;

