import * as React from "react";
import { Header } from "semantic-ui-react";

interface TopPageSubHeaderProps extends React.HTMLProps<HTMLDivElement> {
  content: string;
  sub?: string;
  inverted?: boolean;
}

const TopPageSubHeader: React.FC<TopPageSubHeaderProps> = (props) => {
  return (
    <Header inverted={props.inverted} as="h2" id={props.id}>
      <Header.Content>
        {props.content}
        <Header.Subheader>{props.sub}</Header.Subheader>
      </Header.Content>
    </Header>
  );
}

export default TopPageSubHeader;
