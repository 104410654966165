import * as React from "react";
import { withLayout } from "../components/Layout";
import TopPageSubHeader from "../components/TopPageSubHeader";
import { Segment, Container, Header, Menu, Button, Grid, Item } from "semantic-ui-react";
import { SlidesYamlConnection, VideosYamlConnection, ConnectYamlConnection, FeedCybozuInsideOutConnection, FeedKintoneEngineeringBlogConnection, FeedVietnamTechBlogConnection, FeedKintoneEngineeringBlogEdge } from "../graphql-types";
import Videos from "../components/Videos";
import Slides from "../components/Slides";
import GitHubOrgCards from "../components/GitHubOrgCards";
import RecentConnects from "../components/RecentConnects";
import Title from "../components/Title";
import Masthead from "../components/Masthead";
import HatenaFeedList from "../components/HatenaFeedList";
import GatsbyFeedList from "../components/GatsbyFeedList";
import { Link, graphql } from "gatsby";
import Search from "../components/Search";
import ClientOnly from "../components/ClientOnly";
import { Helmet } from "react-helmet";

const pageTitle = "Top";

interface IndexPageProps {
  data: {
    feeds: FeedCybozuInsideOutConnection;
    englishBlogFeeds: FeedKintoneEngineeringBlogConnection;
    vietnamBlogFeeds: FeedVietnamTechBlogConnection;
    slides: SlidesYamlConnection;
    videos: VideosYamlConnection;
    connects: ConnectYamlConnection;
  };
}

const IndexPage: React.FC<IndexPageProps> = (props) => {
  const feeds = props.data.feeds.edges;
  const englishBlogFeeds = props.data.englishBlogFeeds.edges;
  const vietnamBlogFeeds = props.data.vietnamBlogFeeds.edges;
  const slides = props.data.slides.edges;
  const videos = props.data.videos.edges;

  type Connect = {
    name: string;
    url: string;
    logo: string;
    date: string;
  }

  // ロゴで重複排除したものを最新３件を取得
  const connects = props.data.connects.group.map((g: ConnectYamlConnection) => g.nodes[0])
    .sort((x: Connect, y: Connect) => new Date(y.date).getTime() - new Date(x.date).getTime())
    .slice(0, 3);

  return (
    <Container fluid className="toppage">
      {/* Helmet Topページだけは説明文を入れておきたい。全ページにちゃんと入れるなLayoutでやるべきかも。 */}
      <Helmet>
        <meta name="description" content="サイボウズのエンジニアの技術情報発信をまとめたエンジニアサイトです。" />
      </Helmet>

      <Title title={pageTitle} noHeader />
      {/* Master head */}
      <Masthead>
        <Header as="h1" textAlign="center" inverted>Cybozu Tech</Header>
      </Masthead>

      {/* Search */}
      <Container>
        <Segment vertical padded="very">
          <ClientOnly>
            <Search />
          </ClientOnly>
        </Segment>
      </Container>

      {/* Blog */}
      <Container fluid className="blog bg-sabo">
        <Container>
          <Grid doubling columns={2}>
            <Grid.Row>
              <Grid.Column>
                {/* Cybozu inside Out */}
                <Segment vertical padded="very">
                  <TopPageSubHeader
                    content="Cybozu Inside Out"
                    sub="サイボウズのエンジニアが執筆しているブログです"
                    id="cybozuinsideout"
                  />
                  <Item.Group link divided relaxed="very">
                    <HatenaFeedList feeds={feeds}/>
                  </Item.Group>
                  <Container textAlign="center">
                    <Button as="a" href="https://blog.cybozu.io" target="blank">もっと記事を読む</Button>
                  </Container>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                {/* Kintone Engineering Blog */}
                <Segment vertical padded="very">
                  <TopPageSubHeader
                    content="Kintone Engineering Blog"
                    sub="Learn about Kintone's engineering efforts."
                    id="kintonecomblog"
                  />
                  <Item.Group link divided relaxed="very">
                    <HatenaFeedList feeds={englishBlogFeeds}/>
                  </Item.Group>
                  <Container textAlign="center">
                    <Button as="a" href="https://blog.kintone.io" target="blank">Read more</Button>
                  </Container>
                </Segment>
                <Segment vertical padded="very">
                  <TopPageSubHeader
                    content="Cybozu Vietnam Tech Sharing"
                    sub="This is a technical blog provided by engineers of Cybozu Vietnam Co., Ltd."
                    id="vietnamtechblog"
                  />
                  <Item.Group link divided relaxed="very">
                    <GatsbyFeedList feeds={vietnamBlogFeeds}/>
                  </Item.Group>
                  <Container textAlign="center">
                    <Button as="a" href="https://tech.cybozu.vn" target="blank">Read more</Button>
                  </Container>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Container>
        
      {/* Slides */}
      <Container fluid className="slides">
        <Container>
          <Segment vertical padded="very">
            <TopPageSubHeader
              content="Slides"
              sub="サイボウズのエンジニアが発表した資料や、研修で使った資料などを公開しています"
              id="slides"
              inverted
            />
            <Container style={{padding: 16}}>
              <Slides slides={slides} />
            </Container>
            <Container textAlign="center">
              <Button inverted as="a" href="/slides/">もっと資料を読む</Button>
            </Container>
          </Segment>
        </Container>
      </Container>

      {/* Videos */}
      <Container fluid className="videos bg-sabo">
        <Container>
          <Segment vertical padded="very">
            <TopPageSubHeader
              content="Videos"
              sub="過去の発表動画などをまとめています"
              id="videos"
            />
            <Videos videos={videos} row={3} />
            <Container textAlign="center">
              <Button as="a" href="/videos/">もっと動画を見る</Button>
            </Container>
          </Segment>
        </Container>
      </Container>

      {/* OSS */}
      <Container fluid className="oss">
        <Container>
          <Segment vertical padded="very">
            <TopPageSubHeader
              content="Open Source Software"
              sub="サイボウズではOSSポリシーや多数のOSSを公開しています"
              id="oss"
              inverted
            />
            <Header inverted as="h3">GitHub Org</Header>
            <Container style={{padding: 16}}>
              <GitHubOrgCards />
            </Container>
            <Container textAlign="center">
              <Button as="a" href="/oss/" inverted>詳しい情報を読む</Button>
            </Container>
          </Segment>
        </Container>
      </Container>
        
      {/* Connects */}
      <Container fluid className="techplace bg-sabo">
        <Container>
          <Segment vertical padded="very">
            <TopPageSubHeader
              content="Connects"
              sub="サイボウズがこれまでに応援したイベント・コミュニティです"
              id="connects"
            />
            <RecentConnects connects={connects}/>
            <Container textAlign="center">
              <Button as="a" href="/connects/">過去のイベント・コミュニティを見る</Button>
            </Container>
          </Segment>
        </Container>
      </Container>

      {/* Link */}
      <Segment inverted vertical padded="very">
        <Container className="linkcollection">
          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>
                <Menu fluid vertical text inverted secondary>
                  <Menu.Item link as="a" href="https://cybozu.co.jp/" target="_blank" rel="noreferrer noopener">サイボウズ企業情報</Menu.Item>
                  <Menu.Item link as="a" href="https://www.cybozu.com/jp/" target="_blank" rel="noreferrer noopener">cybozu.com</Menu.Item>
                  <Menu.Item link as="a" href="https://cybozu.co.jp/recruit/" target="_blank" rel="noreferrer noopener">サイボウズ | 採用情報（新卒・キャリア）</Menu.Item>
                  <Menu.Item link as="a" href="https://labs.cybozu.co.jp/" target="_bralnk" rel="noreferrer noopener">サイボウズ・ラボ</Menu.Item>
                  <Menu.Item link as="a" href="https://a11y.cybozu.io/" target="_bralnk" rel="noreferrer noopener">cybozu accessibility</Menu.Item>
                </Menu>
              </Grid.Column>
              <Grid.Column width={6}>
                <Menu fluid vertical text inverted secondary>
                  <Menu.Item link as="a" href="https://cybozu.github.io/frontend-expert" target="_blank" rel="noreferrer noopener">Cybozu Frontend Expert Team</Menu.Item>
                  <Menu.Item link as="a" href="https://zenn.dev/topics/productivityweekly" target="_bralnk" rel="noreferrer noopener">ProductivityWeekly</Menu.Item>
                  <Menu.Item link as="a" href="https://note.com/cybozu_design/" target="_bralnk" rel="noreferrer noopener">note | サイボウズ デザイン&リサーチ</Menu.Item>
                  <Menu.Item link as="a" href="https://note.com/cybozutech/magazines" target="_blank" rel="noreferrer noopener">note | さぼてっく【サイボウズ 開発本部 コネクト支援チーム】</Menu.Item>
                  <Menu.Item link as={Link} to="/Cybozu-Garoon-Dev/">Cybozu Garoon開発まとめ</Menu.Item>
                  <Menu.Item link as={Link} to="/Cybozu-QA/">Cybozu QAまとめ</Menu.Item>
                </Menu>
              </Grid.Column>
              <Grid.Column width={4}>
                <Menu fluid vertical text inverted secondary>
                  <Menu.Item link as={Link} to="/about/">about us</Menu.Item>
                </Menu>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    </Container>
  );
};

export default withLayout(IndexPage, pageTitle);

export const pageQuery = graphql`
query PageIndex {
  # Get feeds
  feeds: allFeedCybozuInsideOut(
    sort: {fields: isoDate, order: DESC},
    limit: 3,
  ) {
    edges {
      node {
        id
        title
        link
        content
        isoDate
        enclosure {
          url
        }
      }
    }
  }

  # Get blog.kintone.io's feeds
  englishBlogFeeds: allFeedKintoneEngineeringBlog(
    sort: {fields: isoDate, order: DESC},
    limit: 1
  ) {
    edges {
      node {
        id
        title
        link
        content
        isoDate
        enclosure {
          url
        }
      }
    }
  }

  # Get tech.cybozu.vn's feeds
  vietnamBlogFeeds: allFeedVietnamTechBlog(
    sort: {fields: isoDate, order: DESC},
    limit: 1
  ) {
    edges {
      node {
        id
        title
        link
        content {
          encoded
        }
        isoDate
        enclosure {
          url
        }
      }
    }
  }

  # Get slides
  slides: allSlidesYaml(
    sort: { order: DESC, fields: [updatedDate, title] }
    limit: 3
  ) {
    totalCount
    edges {
      node {
        id
        title
        tags
        createdDate(formatString: "DD MMMM, YYYY")
        updatedDate(formatString: "DD MMMM, YYYY")
        authors {
          name
          twitter
        }
        url
        thumbnail
        video
      }
    }
  }

  # Get videos
  videos: allVideosYaml(
    sort: { order: DESC, fields: [createdDate, title]},
    limit: 3
  ) {
    totalCount
    edges {
      node {
        id
        yamlId
        title
        createdDate(formatString: "DD MMMM, YYYY")
        service
        id
        url
      }
    }
  }

  # Get recent events
  connects: allConnectYaml(
    filter: {
      category: { eq: "event" }
      logo: { ne: null }
    }
    sort: { fields: date, order: DESC }
  ) {
    group(field: logo, limit: 1) {
      nodes {
        id
        name
        logo
	date
      }
    }
  }

}
`;
