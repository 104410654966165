import * as React from 'react'

export interface Props {
  children: React.ReactElement;
}

const Masthead: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  children,
}) => (
  <div className="masthead">
    { children }
  </div>
);

export default Masthead;
