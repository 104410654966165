import * as React from "react";
import { ConnectYaml } from "../graphql-types";
import { Card, Image } from "semantic-ui-react";

interface ConnectCardProps {
  connect: ConnectYaml;
}

const ConnectCard: React.FC<ConnectCardProps> = ({ connect }) => {
  if (connect.logo) {
    return (
      <Card key={connect.name} link href={connect.url} target="_blank" rel="noreferrer noopener">
        <div className="connect-card-image-wrapper">
        <Image src={ "/images/connect/" + connect.logo } size="medium" alt={ connect.name } title={ connect.name } className="connect-card-image"/>
        </div>
        <Card.Content>
          <Card.Header>{ connect.name }</Card.Header>
        </Card.Content>
      </Card>
    );
  }
  return (
    <Card key={connect.name} link href={connect.url} target="_blank" rel="noreferrer noopener">
      <Card.Content>
        <Card.Header>{ connect.name }</Card.Header>
      </Card.Content>
    </Card>
  );
};

export default ConnectCard;
