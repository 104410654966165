import * as React from "react";
import { Helmet } from "react-helmet";

const Search = () => {
  return (
    <div>
      <Helmet><script async src="https://cse.google.com/cse.js?cx=4b133002841a31dc9"></script></Helmet>
      <div className="gcse-search"></div>
    </div>
  )
}
export default Search;